import React, { useContext, useEffect, useState } from 'react'
import { Tree, Checkbox, Input, Select } from 'antd'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { DATA_NEW, DATA_TEST, MENU_DATA, OPTIONS_SELECT_FILTER } from './constant'

// import { flattenChannelChildren } from '../../helpers'
import { RiSearchLine } from 'react-icons/ri'
import { DataNode } from 'antd/lib/tree'
import { dimensionPopupContext } from 'Containers/DimensionFilters/Dimensions.Popup'
import { StoreProps } from 'Models/dimensions'
import { useHistory } from 'react-router-dom'
import queryString from 'query-string'
export interface StoreNode extends DataNode {
  store_code?: string
  storeKey?: string
}
const DimensionsMktChannelPCM = () => {
  const history = useHistory()
  const search = queryString.parse(history.location.search)
  const filterUuidParam = search?.filter_uuid
  const { setDimensionsDraft, editingDimension, setEditingDimension, dimensionsDraft } =
    useContext(dimensionPopupContext)
  const { productCategoryGroupPCM } = useStoreState((state) => state.dimensions)
  const [valueSelect, setValueSelect] = useState<string[]>([])
  const [data, setData] = useState<StoreNode[] | any>([])
  const [checkedKeys, setCheckedKeys] = useState<string[]>([])
  const [searchText, setSearchText] = useState('')
  const [originalData, setOriginalData] = useState<StoreNode[]>([])
  const [expandKeys, setExpandKeys] = useState<string[]>([])
  const [expandedParents, setExpandedParents] = useState<boolean>(false)
  useEffect(() => {
    if (productCategoryGroupPCM) {
      const TreeData = productCategoryGroupPCM[0]?.dimensions[0]?.values[0]?.tree.children
      setData(TreeData)
      setOriginalData(TreeData)
    }
  }, [productCategoryGroupPCM])

  useEffect(() => {
    const newDataSelected = getKeySelect(data)
    setCheckedKeys(newDataSelected)
  }, [valueSelect])

  useEffect(() => {
    if (dimensionsDraft) {
      let dataSelected: string[] = []
      dimensionsDraft.forEach((dim) => {
        if (dim?.segments[0].type === 'PRODUCT_CATEGORY_PCM') {
          dataSelected = dim?.segments[0].value.split(',')
        }
      })
      setCheckedKeys(dataSelected)
    }
  }, [dimensionsDraft])

  const onSetDimensions = (value: string[]) => {
    setDimensionsDraft({
      type: 'addChannel',
      payload: {
        dimension: {
          segments: [
            {
              audience_Type: 'audience',
              type: 'PRODUCT_CATEGORY_PCM',
              operator: 'IN_THE_FOLLOWING',
              value: value.toString(),
              time_value: '',
            },
          ],
          condition: dimensionsDraft[1]?.condition || 'AND',
        },
      },
    })
  }

  const FilterData = (condition) => (data) => {
    return data.reduce((acc, obj) => {
      if (obj.children) {
        return [...acc, { ...obj, children: FilterData(condition)(obj.children) }]
      } else if (condition(obj)) {
        return [...acc, obj]
      }
      return acc
    }, [])
  }

  const FilterDataSelected = (condition) => (data) => {
    return data.reduce((acc, obj) => {
      if (obj.children)
        return [...acc, { ...obj, children: FilterDataSelected(condition)(obj.children) }]
      else if (condition(obj)) return [...acc, obj]
      return acc
    }, [])
  }

  const getKeyChildren = (condition) => (data) => {
    return data.reduce((acc, obj) => {
      if (obj.children) {
        return [...acc, ...getKeyChildren(condition)(obj.children)]
      } else if (condition(obj)) {
        return [...acc, obj.key]
      }
      // return acc
    }, [])
  }

  const handleExpand = (keys, nodes) => {
    setExpandKeys((prev) => {
      if (nodes.expanded) {
        return [...prev, nodes.node.key]
      } else {
        setExpandedParents(false)
        return prev.filter((item) => item != nodes.node.key)
      }
    })
  }

  let getAllKeys = (tree: any) => {
    let result: any = []
    tree.forEach((x) => {
      let childKeys = []
      if (x.children) {
        childKeys = getAllKeys(x.children)
      }
      result.push(...[x.key, ...childKeys])
    })
    return result
  }

  const getKeySelect = (tree: any) => {
    let result: any = []
    tree.forEach((item) => {
      let childrenKey = []
      if (item.children) {
        childrenKey = getKeySelect(item.children)
      } else {
        if (valueSelect.length == 2) {
          const value1 = valueSelect[0]
          const value2 = valueSelect[1]
          if (
            item.title.toLowerCase().includes(value1) ||
            item.title.toLowerCase().includes(value2) ||
            item.title.toLowerCase().includes('mini')
          ) {
            result.push(...[item.key, ...childrenKey])
          }
        }
        if (valueSelect.length == 1) {
          const value1 = valueSelect[0]
          if (value1.includes('mini')) {
            if (item.title.toLowerCase().includes('mini')) {
              result.push(...[item.key, ...childrenKey])
            }
          } else {
            if (
              item.title.toLowerCase().includes(value1) &&
              !item.title.toLowerCase().includes('mini')
            ) {
              result.push(...[item.key, ...childrenKey])
            }
          }
        }
      }
      result.push(...childrenKey)
    })
    return result
  }

  const filterTreeNode = (node: any) => {
    return node?.title?.toLowerCase().includes(searchText.toLowerCase())
  }

  const handleChange = (value) => {
    // console.log(`selected ${value}`)
  }

  const onChangeSearch = (event) => {
    setSearchText(event.target.value)
    if (event.target.value.length > 2) {
      const hasSearchTerm = (n) => n.toLowerCase().includes(event.target.value.toLowerCase())
      const filterData = (arr) =>
        arr?.filter((n) => hasSearchTerm(n.title) || filterData(n.children)?.length > 0)
      const filteredData = filterData(originalData).map((n) => {
        return {
          ...n,
          children: filterData(n.children),
        }
      })
      const getExpandedKeys = (tree: any) => {
        return tree.reduce((acc, obj) => {
          if (obj.children) {
            if (hasSearchTerm(obj.title)) return [...acc, obj.key, ...getExpandedKeys(obj.children)]
            else return [...acc, ...getExpandedKeys(obj.children)]
          } else {
            if (hasSearchTerm(obj.title)) return [...acc, obj.key]
            else return acc
          }
        }, [])
      }
      setData(filteredData)
      setExpandKeys(getExpandedKeys(filteredData))
      setExpandedParents(true)
    } else {
      setData(originalData)
      setExpandKeys(checkedKeys)
      setExpandedParents(checkedKeys ? true : false)
    }
  }

  const onCheck = (e) => {
    const { node, checked } = e
    if (checked) {
      if (node?.children) {
        const childrenKeys = getKeyChildren(() => true)(node?.children)
        setCheckedKeys((prev) => {
          const withoutDuplicates = [...new Set([...prev, ...childrenKeys])]
          onSetDimensions(withoutDuplicates)
          return withoutDuplicates
        })
      } else {
        setCheckedKeys((prev) => {
          onSetDimensions([...prev, node.key])
          return [...prev, node.key]
        })
      }
    } else {
      if (node?.children) {
        const childrenKeys = getKeyChildren(() => true)(node?.children)
        setCheckedKeys((prev) => {
          const removeKeys = prev.filter((item) => !childrenKeys.includes(item))
          onSetDimensions(removeKeys)
          if (!removeKeys.toString()) {
            dimensionsDraft &&
              dimensionsDraft.map((dimension, index) => {
                if (dimension?.segments[0].type == 'PRODUCT_CATEGORY_PCM') {
                  setDimensionsDraft({ type: 'remove', payload: { index } })
                }
              })
          }

          return removeKeys
        })
      } else {
        setCheckedKeys((prev) => {
          const removeKeys = prev.filter((item) => item != node?.key)
          onSetDimensions(removeKeys)
          if (!removeKeys.toString()) {
            dimensionsDraft &&
              dimensionsDraft.map((dimension, index) => {
                if (dimension?.segments[0].type == 'PRODUCT_CATEGORY_PCM') {
                  setDimensionsDraft({ type: 'remove', payload: { index } })
                }
              })
          }
          return removeKeys
        })
      }
    }
  }

  const handleOnChangeSelect = (e) => {
    setValueSelect(e)
  }

  return (
    <div className="py-6 px-8">
      <div className="flex items-center justify-between">
        <div className="flex-1 ">
          <Input
            className="rounded"
            onChange={(e) => onChangeSearch(e)}
            value={searchText}
            placeholder="Search"
            prefix={<RiSearchLine />}
          />
        </div>
      </div>
      <div className="flex items-center justify-between mt-4 mb-2 py-1">
        <div>Business status</div>
        <div>Activated</div>
      </div>
      <div className="flex items-center justify-between mb-2 py-1">
        <div>Inventory status</div>
        <div>All</div>
      </div>
      <div className="border border-divider"></div>
      <div style={{ marginLeft: -9 }} className="mt-2 ">
        <Tree
          checkedKeys={checkedKeys || undefined}
          expandedKeys={expandKeys}
          autoExpandParent={expandedParents}
          checkable
          onCheck={(keys, nodes) => {
            onCheck(nodes)
          }}
          onExpand={(keys, nodes) => {
            handleExpand(keys, nodes)
          }}
          treeData={data}
          filterTreeNode={filterTreeNode}
        />
      </div>
    </div>
  )
}

export default DimensionsMktChannelPCM
