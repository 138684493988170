import axios from 'Utils/axios'
import React, { useEffect, useState } from 'react'

export default function useFetchDimensions() {
  const [values, setValues] = useState([])
  const [keyword, setKeyword] = useState('')
  const fetchSegments = async () => {
    try {
      const resp = await axios.get('/audience-dimensions/', {
        params: {
          query: keyword || '',
          group: 'segment',
          page: 'segment',
          as_type: 'kariba',
          company: 'kingfood',
        },
      })
      setValues(
        resp.data[0]?.dimensions[0]?.value?.map((op) => ({
          label: op?.name?.toString(),
          value: op?.code?.toString(),
          ...op,
        }))
      )
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    fetchSegments()
  }, [keyword])

  return {
    values,
    setKeyword,
  }
}
