import { CustomerGroup } from 'DimensionsFiltersTypes'

export const DIMENSION_GROUPS_FILTER = [
  { value: 'customer', label: 'Audience' },
  // Chưa có data, uncomment sau khi có data
  // { value: 'segment', label: 'Segment' },
  // { value: 'campaign', label: 'Marketing Campaign' },
  { value: 'channel', label: 'Sales Channel' },
  { value: 'store', label: 'Sales Store' },
]

export const DIMENSION_GROUPS_SEGMENTS_FILTER = [
  { value: 'customer', label: 'Audience' },
  // Chưa có data, uncomment sau khi có data
  { value: 'segment', label: 'Segment' },
  // { value: 'campaign', label: 'Marketing Campaign' },
  // { value: 'channel', label: 'Marketing Channel' },
]

export const DIMENSION_GROUPS_CHANNEL_FILTER = [
  // { value: 'customer', label: 'Audience' },
  // Chưa có data, uncomment sau khi có data
  // { value: 'segment', label: 'Segment' },
  // { value: 'campaign', label: 'Marketing Campaign' },
  { value: 'channel', label: 'Sales Channel' },
]

export const DIMENSION_GROUPS_PRODUCT_FILTER = [
  { value: 'brand', label: 'Brand' },
  { value: 'product_category', label: 'Product Category' },
  { value: 'product_category_pcm', label: 'Product Category PCM' },
  { value: 'channel', label: 'Sales Channel' },
  { value: 'supplier', label: 'Supplier' },
  { value: 'physical_store', label: 'Physical store' },
]

export const CAMPAIGN_GROUP = [
  { value: '1', label: 'Sale Khổ qua' },
  { value: '2', label: 'Test 123' },
  { value: '3', label: 'Web push' },
]

export const CHANNEL_GROUP = [
  { key: 'facebook_ads', title: 'Facebook Ads' },
  { key: 'google_ads', title: 'Google Ads' },
  {
    key: 'chat',
    title: 'Chat',
    children: [
      { key: 'store_farming', title: 'Store Farming' },
      { key: 'zalo', title: 'Zalo' },
      { key: 'messenger', title: 'Facebook Messenger' },
    ],
  },
  {
    key: 'web',
    title: 'Website',
    children: [
      { key: 'manual', title: 'Manual Targeted Push' },
      { key: 'feedback', title: 'Popup - Feedback' },
    ],
  },
]
