import { Input, Tree } from 'antd'
import React, { Key, useEffect, useMemo, useState } from 'react'
import SearchDropdown from '../SearchDropdown'
import { DataNode } from 'antd/lib/tree'
import _, { uniq } from 'lodash'
import { useDebounce } from 'Hooks'
import '../index.scss'

const a = [{ id: '1123', code: '123', children: [{ id: '123', code: '123' }] }]

interface props {
  handleSearch?: (keyword: string) => void
  options?: unknown[]
  values?: string[]
  onChange: (value: string[]) => void
  isSingleSelect?: boolean
}
export default function TreeSelect({
  handleSearch,
  options,
  values,
  onChange,
  isSingleSelect,
}: props) {
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined)
  const debounceSearchQuery = useDebounce(searchQuery, 500)

  function formatTreeArray(arr: any[]) {
    arr.forEach((item) => {
      if (item.code && !item.key) {
        item.key = item.code
        item.title = item.name
        delete item.code
      }

      if (item.children && Array.isArray(item.children)) {
        formatTreeArray(item.children)
      }
    })
    return arr
  }

  function findParentKeys(data, childKeys) {
    let parentKeys: string[] = []

    function search(data, parentStack) {
      if (Array.isArray(data)) {
        data.forEach((item) => {
          // Update the parent stack for the current level
          let newParentStack = [...parentStack]
          if (item.key) {
            newParentStack.push(item.key)
          }

          // Check if the current item is one of the child keys
          if (Array.isArray(childKeys) && childKeys.includes(item.key)) {
            // Add all parent keys in the stack to the result
            parentKeys = uniq([...parentKeys, ...newParentStack])

            return
          }

          // Recursively search in children
          if (item.children && item.children.length > 0) {
            search(item.children, newParentStack)
          }
        })
      }
    }

    search(data, [])
    return parentKeys
  }

  function getLastNestedChildrenArray(node, result = [] as any[]) {
    if (!node.children || node.children.length === 0) {
      result.push(node)
    } else {
      node.children.forEach((child) => {
        getLastNestedChildrenArray(child, result)
      })
    }
    return result
  }

  const memoTree = useMemo(() => {
    return (
      <Tree
        checkedKeys={{
          checked: values || [],
          halfChecked: findParentKeys(formatTreeArray(options || []), values),
        }}
        checkable
        treeData={formatTreeArray(options || [])}
        onCheck={(checkedKeys: any, e) => {
          if (isSingleSelect) {
            onChange(uniq(checkedKeys))
          } else {
            const testList = e.checkedNodes
              .reduce((acc, node) => {
                return acc.concat((getLastNestedChildrenArray(node) || []) as never[])
              }, [])
              ?.map((item: any) => item.key)

            const list = values?.some((i) =>
              getLastNestedChildrenArray(e?.node)
                ?.map((i) => i?.key)
                ?.includes(i)
            )
              ? values?.filter((item) => testList?.includes(item))
              : values?.concat(testList)
            onChange(uniq(list as string[]))
          }
        }}
      />
    )
  }, [options, values])

  useEffect(() => {
    if (debounceSearchQuery !== undefined && handleSearch) handleSearch(debounceSearchQuery)
  }, [debounceSearchQuery])

  useEffect(() => {
    if (handleSearch) handleSearch('')
  }, [])

  return (
    <div>
      <SearchDropdown
        values={values}
        customDropdown={() => {
          return (
            <div
              style={{
                width: 500,
              }}
            >
              <Input
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
              <div className="mt-2"></div>
              {memoTree}
            </div>
          )
        }}
        onChange={() => {}}
      />
    </div>
  )
}
