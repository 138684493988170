import React, { useState, useEffect } from 'react'
import { Checkbox } from 'antd'

import { useStoreActions, useStoreState } from 'easy-peasy'

const DimensionsMktCampaign = () => {
  const { dimensionsDraft, campaignGroup } = useStoreState((state: any) => state.dimensions)
  const { onAddDimension, onChangeDimension, onRemoveDimension } = useStoreActions(
    (action: any) => action.dimensions
  )

  const dimensionType = 'CAMPAIGN'

  const campaignDimensionIndex = dimensionsDraft.findIndex(
    (item) => item?.dimension?.segments[0]?.type === dimensionType
  )
  const checkedKeys =
    dimensionsDraft[campaignDimensionIndex]?.dimension?.segments[0].value?.split(',') || []

  const [indeterminate, setIndeterminate] = useState(false)
  const [isCheckedAll, setIsCheckedAll] = useState(checkedKeys.length === campaignGroup.length)

  const onCheckKeys = (keys: string[]) => {
    const dimensionValue = {
      segments: [{ type: dimensionType, operator: 'EQUAL', value: keys.toString() || '' }],
      condition: 'AND',
    }

    if (!keys.length) {
      onRemoveDimension(campaignDimensionIndex)
      return
    }

    if (campaignDimensionIndex >= 0) {
      onChangeDimension({
        index: campaignDimensionIndex,
        newDimension: dimensionValue,
      })
      return
    }

    onAddDimension(dimensionValue)
  }

  const onCheckAllChange = (e) => {
    const dimensionValue = {
      segments: [
        {
          type: dimensionType,
          operator: 'EQUAL',
          value: campaignGroup.map((campaign) => campaign.value).toString(),
        },
      ],
      condition: 'AND',
    }

    if (!e.target.checked) {
      onRemoveDimension(campaignDimensionIndex)
      return
    }

    if (campaignDimensionIndex >= 0) {
      onChangeDimension({
        index: campaignDimensionIndex,
        newDimension: dimensionValue,
      })
      return
    }

    onAddDimension(dimensionValue)
  }

  useEffect(() => {
    setIndeterminate(checkedKeys.length < campaignGroup.length && checkedKeys.length > 0)
    setIsCheckedAll(checkedKeys.length === campaignGroup.length)
  }, [checkedKeys, campaignGroup])

  return (
    <div className="ml-4 p-4">
      <Checkbox indeterminate={indeterminate} onChange={onCheckAllChange} checked={isCheckedAll}>
        <div style={{ marginLeft: 4, fontSize: 16 }}>All</div>
      </Checkbox>
      <Checkbox.Group
        value={checkedKeys}
        options={campaignGroup}
        onChange={(value: any) => onCheckKeys(value)}
      />
    </div>
  )
}

export default DimensionsMktCampaign
