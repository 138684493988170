import React, { useMemo } from 'react'
import { useStoreState } from 'easy-peasy'
import { StoreProps } from 'Models/dimensions'
import { Segment } from 'DimensionsFiltersTypes'

import { flattenChannelChildren, flattenCustomerSegments } from '../helpers'
import { capitalizeFirstLetter } from 'Utils'

export type props = {
  segment: Segment
  className?: string
}

const OPERATORS = {
  EQUAL: '=',
  IN_THE_FOLLOWING: 'in the following',
}

const DimensionInterfaceLabel: React.FC<props> = ({ segment, className }) => {
  const { type, operator, value, time_operator, time_value, name, audience_Type, count } = segment
  const {
    customerGroup,
    channelGroup,
    campaignGroup,
    brandGroup,
    supplierGroup,
    productCategoryGroup,
    storeGroup,
    physicalStoreGroup,
  } = useStoreState((state: { dimensions: StoreProps }) => state.dimensions)

  const recursionTree = (label: any, data: any) => {
    let result: any = []
    data.forEach((item) => {
      let childKeys: any = []
      if (item.children) {
        childKeys = recursionTree(label, item.children)
      }
      if (label.includes(item.key)) {
        result.push(...[item.title, ...childKeys])
      } else {
        result.push(...childKeys)
      }
    })

    return result
  }
  const renderSegmentLabel = useMemo(() => {
    if (type === 'SALE_CHANNEL') {
      let channelLabelValue = segment?.value.split(',')
      let channelLabel = ''
      if (channelLabelValue?.length > 2) {
        const temp = channelLabelValue?.slice(0, 2)
        channelLabel = `${temp.toString()} + ${(channelLabelValue?.length - 2).toString()} others`
      }

      return `Channel = ${
        channelLabelValue?.length <= 2 ? channelLabelValue?.toString() : channelLabel
      }`
    }
    if (type === 'SUPPLIER') {
      let channelLabelValue = segment?.value.split(',')
      let getTitle: string[] = []
      supplierGroup &&
        supplierGroup[0]?.dimensions[0]?.values?.forEach((element) => {
          if (channelLabelValue.includes(element.key)) {
            getTitle.push(element.title)
          }
        })
      let channelLabel = ''
      if (getTitle.length > 2) {
        const temp = getTitle?.slice(0, 2)
        channelLabel = `${temp.toString()} + ${(getTitle?.length - 2).toString()} others`
      }

      return `Supplier = ${getTitle?.length <= 2 ? getTitle?.toString() : channelLabel}`
    }

    if (type === 'BRAND') {
      let channelLabelValue = segment?.value?.split(',')
      let getTitle: string[] = []
      brandGroup &&
        brandGroup[0]?.dimensions[0]?.values?.forEach((element) => {
          if (channelLabelValue.includes(element.key)) {
            getTitle.push(element.title)
          }
        })
      let channelLabel = ''
      if (getTitle?.length > 2) {
        const temp = getTitle?.slice(0, 2)
        channelLabel = `${temp?.toString()} + ${(getTitle?.length - 2)?.toString()} others`
      }

      return `Brand = ${getTitle?.length <= 2 ? getTitle?.toString() : channelLabel}`
    }

    if (type === 'PRODUCT_CATEGORY') {
      let channelLabelValue = segment?.value?.split(',')
      // console.log('channelLabelValue', channelLabelValue)
      let getTitle = recursionTree(
        channelLabelValue,
        productCategoryGroup && productCategoryGroup[0]?.dimensions[0]?.values[0]?.tree?.children
      )
      // console.log('recursionTree ', getTitle)
      // productCategoryGroup &&
      //   productCategoryGroup[0]?.dimensions[0]?.values[0]?.tree.children.forEach((element) => {
      //     if (channelLabelValue.includes(element.key)) {
      //       getTitle.push(element.title)
      //     }
      //   })
      let channelLabel = ''
      if (getTitle?.length > 2) {
        const temp = getTitle?.slice(0, 2)
        channelLabel = `${temp?.toString()} + ${(getTitle?.length - 2)?.toString()} others`
      }
      return `Business Status = Activated, Inventory = All; Category = ${
        getTitle?.length <= 2 ? getTitle?.toString() : channelLabel
      }`
    }
    if (type === 'PHYSICAL_STORE') {
      let channelLabelValue = segment?.value?.split(',')
      // console.log('channelLabelValue', channelLabelValue)
      let getTitle = recursionTree(
        channelLabelValue,
        physicalStoreGroup && physicalStoreGroup[0]?.dimensions[0]?.tree
      )

      let channelLabel = ''
      if (getTitle?.length > 2) {
        const temp = getTitle?.slice(0, 2)
        channelLabel = `${temp?.toString()} + ${(getTitle?.length - 2)?.toString()} others`
      }

      return `Physical store = ${getTitle?.length <= 2 ? getTitle?.toString() : channelLabel}`
    }

    if (type === 'STORE') {
      let channelLabelValue = segment?.value?.split(',')
      // console.log('channelLabelValue', channelLabelValue)
      let getTitle = recursionTree(channelLabelValue, storeGroup && storeGroup[0].children)

      let channelLabel = ''
      if (getTitle?.length > 2) {
        const temp = getTitle?.slice(0, 2)
        channelLabel = `${temp?.toString()} + ${(getTitle?.length - 2)?.toString()} others`
      }

      return `Store = ${getTitle?.length <= 2 ? getTitle?.toString() : channelLabel}`
    }
    if (type === 'CAMPAIGN') {
      const computeCampaignLabel = (value: string) => {
        const arr = value?.split(',')
        const labels = arr?.map(
          (item) => ` ${campaignGroup?.find((campaignItem) => campaignItem?.value === item)?.label}`
        )

        if (labels?.length > 2) {
          const temp = labels?.slice(0, 2)
          return `${temp?.toString()} + ${(labels.length - 2)?.toString()} others`
        }

        return labels?.toString()
      }
      return `Campaign ${OPERATORS[operator]} ${computeCampaignLabel(value?.toString())}`
    }

    if (type === 'SEGMENT') {
      // const segments = customerGroup?.length ? flattenCustomerSegments(customerGroup) : []
      // console.log('segment', segment)
      // const currentSegment = segments.find((segment) => segment.code === type)

      // const valueLabel = name?.toString()?.includes(',')
      //   ? name
      //       ?.toString()
      //       ?.split(',')
      //       ?.map(
      //         (item) =>
      //           currentSegment?.values.find((v) => v.code === item)?.name ||
      //           Number(item).toLocaleString()
      //       )
      //       .join(', ')
      //   : currentSegment?.values.find((v) => v.code === name)?.name ||
      //     name ||
      //     Number(name).toLocaleString()

      return ` ${segment?.name}`
    }

    const segments = customerGroup?.length ? flattenCustomerSegments(customerGroup) : []
    const currentSegment = segments?.find((segment) => segment?.code === type)

    const operatorLabel =
      currentSegment?.operator?.find((opr) => opr?.code === operator)?.name || operator

    const valueLabel = value?.toString()?.includes(',')
      ? value
          ?.toString()
          ?.split(',')
          ?.map(
            (item) =>
              currentSegment?.values?.find((v) => v?.code === item)?.name ||
              Number(item)?.toLocaleString()
          )
          .join(', ')
      : currentSegment?.values?.find((v) => v?.code === value)?.name ||
        value ||
        Number(value)?.toLocaleString()

    const fortmatValueLabel =
      value?.toString()?.split(',')?.length < 5
        ? value?.toString()?.split(',')?.join(', ')
        : `${value?.toString()?.split(',')?.length} items`

    const timeOperatorLabel =
      currentSegment?.time_operator?.find((opr) => opr?.code === time_operator?.name)?.name ||
      time_operator
    return `${capitalizeFirstLetter(audience_Type)} - ${
      currentSegment?.name || type
    } ${operatorLabel}  ${
      segment?.type == 'GENDER' || segment?.type == 'PHONE' ? valueLabel : fortmatValueLabel
    } ${timeOperatorLabel || ''}  ${time_value || ''} ${
      count ? `(count: ${count?.toString()})` : ''
    }`
  }, [
    customerGroup,
    channelGroup,
    campaignGroup,
    type,
    operator,
    value,
    time_operator,
    time_value,
    count,
  ])

  return <div className={className}>{renderSegmentLabel}</div>
}

export default DimensionInterfaceLabel
