import React, { useMemo, useState, useContext, useEffect } from 'react'

import {
  DIMENSION_GROUPS_CHANNEL_FILTER,
  DIMENSION_GROUPS_FILTER,
  DIMENSION_GROUPS_PRODUCT_FILTER,
  DIMENSION_GROUPS_SEGMENTS_FILTER,
} from './constants'
import CustomerGroup from './Customer'
import ChannelGroup from './Channel'
import BranchGroup from './Brand'
import SupplierGroup from './Supplier'
import CampaignGroup from './Campaign'
import ProductCategoryGroup from './ProductCategory'
import ProductCategoryGroupPCM from './ProductCategoryPCM'
import Segment from './Segment'
import StoreGroup from './Store'
import PhysicalStoreGroup from './PhysicalStore'
import DimensionGroupCounter from './DimensionGroup.Counter'

import { dimensionPopupContext } from '../Dimensions.Popup'
import { getDimensions } from 'Services/dimensions'

const DimensionsGroups = ({ activeDimension, segmentOptions }) => {
  const { dimensionsDraft: dimensions, setDimensionsDraft } = useContext(dimensionPopupContext)
  // console.log('setDimensionsDraft', dimensions)
  const [activeGroup, setActiveGroup] = useState(
    activeDimension == '' || activeDimension == undefined ? 'customer' : activeDimension
  )

  const isSegment = window.location.href.split('/')[4] === 'segments'

  useEffect(() => {
    const slugSplit = window.location.pathname.split('/')[2]
    if (slugSplit == 'sale-campaigns') {
      setActiveGroup('channel')
    }
    if (slugSplit == 'products') {
      setActiveGroup('brand')
    }
  }, [window.location.pathname.split('/')[2]])

  const slugSplit = window.location.pathname.split('/')[2]
  const renderDimensionFilter = () => {
    switch (slugSplit) {
      case 'segments':
        return DIMENSION_GROUPS_SEGMENTS_FILTER
      case 'sale-campaigns':
        return DIMENSION_GROUPS_CHANNEL_FILTER
      case 'products':
        return DIMENSION_GROUPS_PRODUCT_FILTER
      default:
        return DIMENSION_GROUPS_FILTER
    }
  }
  const renderGroup = useMemo(() => {
    switch (activeGroup) {
      case 'customer':
        return <CustomerGroup />
      case 'segment':
        return isSegment && <Segment options={segmentOptions} />
      case 'channel':
        return <ChannelGroup />
      case 'brand':
        return <BranchGroup />
      case 'supplier':
        return <SupplierGroup />
      case 'campaign':
        return <CampaignGroup />
      case 'product_category':
        return <ProductCategoryGroup />
      case 'product_category_pcm':
        return <ProductCategoryGroupPCM />
      case 'store':
        return <StoreGroup />
      case 'physical_store':
        return <PhysicalStoreGroup />
    }
  }, [activeGroup])

  return (
    <div className="flex w-full h-full overflow-scroll DimensionGroups">
      <div className="w-1/6 p-4 border-r">
        {renderDimensionFilter().map((group) => (
          <div
            className={`${
              activeGroup === group?.value && 'bg-brand_light'
            } font-semibold px-4 py-2 cursor-pointer rounded flex justify-between items-center`}
            key={group.value}
            onClick={() => {
              setActiveGroup(group.value)
              // activeGroup != group?.value && setDimensionsDraft({ type: 'clear' })
            }}
          >
            <span>{group.label}</span>
            <DimensionGroupCounter group={group.value} dimensions={dimensions} />
          </div>
        ))}
      </div>
      <div className="relative w-full overflow-x-hidden overflow-y-scroll">{renderGroup}</div>
    </div>
  )
}

export default DimensionsGroups
