import React, { useCallback, useMemo } from 'react'
import { useStoreState } from 'easy-peasy'
import { Dimension } from 'DimensionsFiltersTypes'

import { flattenCustomerSegments } from '../helpers'

export type props = {
  group: string
  dimensions: Dimension[]
}

const DimensionsGroupsCounter: React.FC<props> = ({ group, dimensions }) => {
  const { customerGroup } = useStoreState((state: any) => state.dimensions)
  const countValue = (type: string) => {
    let countBrand = 0
    // const valueNumber = dimensions[0]?.segments[0].value.split(',')
    dimensions?.forEach((item) => {
      if (item?.segments[0]?.type == type) {
        countBrand = item?.segments[0]?.value.split(',').length
      }
    })
    return countBrand
  }
  const renderDimensionCounter = useMemo(() => {
    if (group === 'customer') {
      const segments = flattenCustomerSegments(customerGroup)
      const counts: any = []
      dimensions.forEach((item) => {
        const type = item?.segments[0]?.type
        if (segments.some((segment) => segment.code === type)) counts.push(type)
      })

      return counts?.length || 0
    }

    if (group === 'channel') {
      return countValue('SALE_CHANNEL')
    }
    if (group === 'brand') {
      return countValue('BRAND')
    }
    if (group === 'supplier') {
      return countValue('SUPPLIER')
    }
    if (group === 'product_category') {
      return countValue('PRODUCT_CATEGORY')
    }
    if (group === 'product_category_pcm') {
      return countValue('PRODUCT_CATEGORY_PCM')
    }
    if (group === 'physical_store') {
      return countValue('PHYSICAL_STORE')
    }
    if (group === 'store') {
      return countValue('STORE')
    }

    // if (group === 'campaign') {
    //   const campaignDimension = dimensions.find(
    //     (item) => item?.dimension?.segments[0]?.type === 'CAMPAIGN'
    //   )
    //   const checkedKeys = campaignDimension?.dimension?.segments[0].value?.split(',')

    //   return checkedKeys?.length || 0
    // }

    return 0
  }, [group, dimensions, customerGroup])

  if (!renderDimensionCounter) return null

  return (
    <span
      style={{ minWidth: 20, minHeight: 20 }}
      className="flex items-center justify-center px-2 text-white rounded-full bg-brand_primary"
    >
      {renderDimensionCounter}
    </span>
  )
}

export default DimensionsGroupsCounter
