import axios from 'Utils/axios'
import React, { useEffect, useState } from 'react'

export interface IUseFetchDimensions {
  dimension_code?: string
}
export default function useFetchDimensions({ dimension_code }: IUseFetchDimensions) {
  const [options, setOptions] = useState([])
  const [keyword, setKeyword] = useState('')
  const fetchDimensions = async () => {
    try {
      const resp = await axios.get('/audience-dimensions/dimensions', {
        params: {
          dimension_code,
          keyword: keyword || '',
          per_page: 100,
        },
      })
      setOptions(resp.data.data.map((op) => ({ label: op?.name, value: op?.code, ...op })))
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    if (dimension_code) fetchDimensions()
  }, [dimension_code, keyword, keyword === ''])

  return {
    options,
    setKeyword,
  }
}
