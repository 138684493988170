import React, { useState, useEffect, useRef, ReactElement } from 'react'
import { Checkbox, Dropdown, Input } from 'antd'
import { DownOutlined } from '@ant-design/icons'
import { useClickOutside, useDebounce, useKeyPress } from 'Hooks'
import './index.scss'
import { isArray } from 'lodash'

interface props {
  values?: string[]
  onChange: (value: string[]) => void
  options?: { label: string; value: string }[]
  placeholder?: string
  isMulti?: boolean
  handleSearch?: (keyword: string) => void
  customDropdown?: () => ReactElement
  maxWidth?: number
}

const SearchDropdown: React.FC<props> = ({
  values,
  options,
  placeholder,
  isMulti,
  customDropdown,
  onChange,
  handleSearch,
  maxWidth,
}) => {
  const [isVisible, setIsVisible] = useState(false)
  const [searchQuery, setSearchQuery] = useState<string | undefined>(undefined)
  const dropdownRef = useRef(null)
  const ref = useRef(null)

  useClickOutside(ref, setIsVisible, dropdownRef)
  useKeyPress('Enter', (isKeyPressed: boolean) => setIsVisible(!isKeyPressed))
  useKeyPress('Escape', (isKeyPressed: boolean) => setIsVisible(!isKeyPressed))
  const debounceSearchQuery = useDebounce(searchQuery, 500)

  useEffect(() => {
    if (debounceSearchQuery !== undefined && handleSearch) handleSearch(debounceSearchQuery)
  }, [debounceSearchQuery])

  const computeLabel = () => {
    const chosenLabels = (Array?.isArray(values) ? values : [values])?.map(
      (item) => options?.find((op) => op.value === item)?.label || item
    )

    return (
      <div className="truncate w-full">
        {chosenLabels?.length ? (
          chosenLabels?.map((item, index) => {
            if (index > 0) {
              item = ', ' + item
            }
            return item
          })
        ) : (
          <span className="text-disabled_text">{placeholder}</span>
        )}
      </div>
    )
  }
  const MENU = (
    <div
      ref={dropdownRef}
      style={{
        minHeight: 'fit-content',
        width: maxWidth || 208,
        maxHeight: 400,
        overflow: 'scroll',
      }}
      className="CommonInputs-multi-select-dropdown flex flex-col bg-gray_1 p-2 customer-segments-filter-dropdown"
    >
      <Input
        placeholder="Search..."
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
      />

      {values?.length ? (
        <div style={{ marginBottom: '8px' }}>
          {(Array?.isArray(values) ? values : [values])?.map((item) => {
            return (
              <div
                style={{
                  cursor: 'pointer',
                  padding: '5px',
                  paddingLeft: '16px',
                  background: 'var(--blue_1)',
                }}
                onClick={() => {
                  const newList = values?.filter((v) => v !== item)
                  onChange(newList)
                }}
              >
                {item}
              </div>
            )
          })}
        </div>
      ) : null}

      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {options?.map((option) => (
          <Checkbox
            style={{
              margin: 0,
            }}
            checked={values?.includes(option.value)}
            onChange={() => {
              const isSelected = values?.includes(option.value)
              if (!isMulti) {
                onChange(isSelected ? [] : [option.value])
                return
              }

              const newList = isSelected
                ? values?.filter((item) => item !== option.value) || []
                : [...(values || []), option.value]
              onChange(newList)
            }}
            value={option.value}
          >
            {option.label}
          </Checkbox>
        ))}
      </div>
    </div>
  )

  return (
    <Dropdown
      getPopupContainer={(trigger: any) => trigger.parentNode}
      visible={isVisible}
      trigger={['click']}
      overlay={
        customDropdown ? (
          <div
            ref={dropdownRef}
            style={{
              maxHeight: 400,
              overflow: 'scroll',
              padding: 8,
              boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.12)',
              backgroundColor: 'white',
            }}
          >
            {customDropdown()}
          </div>
        ) : (
          MENU
        )
      }
      className="w-full"
    >
      <div
        ref={ref}
        className="py-2 px-3 rounded cursor-pointer flex justify-between items-center border border-gray_5"
        style={{ height: 40, maxWidth: maxWidth || 210 }}
        onClick={() => setIsVisible(true)}
      >
        <span className="flex w-4/5" style={{ minWidth: '100px' }}>
          <span className="w-full">{computeLabel()}</span>
        </span>
        <DownOutlined className="ml-2 text-xs" />
      </div>
    </Dropdown>
  )
}

export default SearchDropdown
