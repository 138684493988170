import React, { useState, useEffect } from 'react'
import { LeftOutlined } from '@ant-design/icons'
import { Select, InputNumber, Button, Input, message } from 'antd'
import { CustomerItem, Segment } from 'DimensionsFiltersTypes'

import CommonInputs from '../../Inputs/CommonInputs'
import TimeInput from './Customer.SegmentScreen.TimeInput'
import SearchDropdown from 'Containers/DimensionFilters/Inputs/CommonInputs/SearchDropdown'
import useFetchDimensions from './useFetchDimensions'
import { stringify } from 'query-string'
import { TREE_DIMENSIONS } from 'Containers/DimensionFilters/constants'
import TreeSelect from 'Containers/DimensionFilters/Inputs/CommonInputs/TreeSelect'

export type props = {
  segment: CustomerItem
  isEditing?: boolean
  initialValues?: Segment
  onOk: (value: Segment) => void
  onCancel: () => void
}

const SegmentScreen: React.FC<props> = ({ segment, onCancel, onOk, isEditing, initialValues }) => {
  const [value, setValue] = useState<string>('')
  const [countValue, setCountValue] = useState<string>('')
  const [operator, setOperator] = useState<string>('')
  const [timeOperator, setTimeOperator] = useState<any>()
  const [timeValue, setTimeValue] = useState<string>('')

  const { options, setKeyword } = useFetchDimensions({
    dimension_code: segment?.code,
  })

  const getValues = (values, v) => {
    const item = values?.find((e) => e?.code === v)
    return item?.values
  }

  const checkNumRegex =
    /[a-zA-ZÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ_<>=^\s+?|$!@#$%\\^&*)(_'"`~/:;{}\[\]-][a-zA-ZÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ_<>=^\s+?|$!@#$%\\^&*)(_'"`~/\[\]-]*/
  function isJsonString(str) {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }
    return true
  }
  useEffect(() => {
    setValue(initialValues?.value || '')
    setCountValue(initialValues?.count?.toString() || '')
    initialValues?.operator && setOperator(initialValues?.operator)
    initialValues?.time_operator && setTimeOperator(initialValues?.time_operator)
    initialValues?.time_value && setTimeValue(initialValues?.time_value?.toString())
  }, [initialValues])

  const isNumber = value
    .toString()
    .split(',')
    .filter((e) => e != '')
    .every((e) => e.match(checkNumRegex))

  const testIsDisable = () => {
    if (value) {
      if (segment.field == 'number') {
        if (operator == 'IS_BETWEEN' || operator == 'IS_NOT_BETWEEN') {
          if (
            value.split(',').length == 2 &&
            typeof parseFloat(value.split(',')[0]) == 'number' &&
            typeof parseFloat(value.split(',')[0]) == 'number' &&
            value?.split(',')[0].normalize('NFC').match(checkNumRegex) == null &&
            value?.split(',')[1].normalize('NFC').match(checkNumRegex) == null
          ) {
            return false
          } else {
            return true
          }
        } else {
          if (isNumber) {
            return true
          } else {
            return false
          }
        }
      } else {
        if (operator == 'IS_BETWEEN' || operator == 'IS_NOT_BETWEEN') {
          if (
            value.split(',').length == 2 &&
            typeof parseFloat(value.split(',')[0]) == 'string' &&
            typeof parseFloat(value.split(',')[0]) == 'string'
          ) {
            return false
          } else {
            return true
          }
        } else {
          // if (!isNumber) {
          return false
          // } else {
          //   return true
          // }
        }
      }
    } else {
      return true
    }
  }

  const renderInput = () => {
    if (segment.field === 'number') {
      return (
        <Input
          style={{ height: 40, borderRadius: 4, width: 150 }}
          placeholder="Value"
          value={value}
          onChange={(e) => {
            e.target.value
              .toString()
              .split(',')
              .filter((e) => e != '')
              .some((e) => e.normalize('NFC').match(checkNumRegex)) &&
              message.warning("Invalid input: input couldn't be text", 0.75)
            setValue(e.target.value.toString())
          }}
        />
      )
    } else {
      return (
        <Input
          style={{ height: 40, borderRadius: 4, width: 150 }}
          placeholder="Value"
          value={value}
          onChange={(e) => {
            !e.target.value
              .toString()
              .split(',')
              .filter((e) => e != '')
              .every((e) => e.normalize('NFC').match(checkNumRegex)) &&
              message.warning("Invalid input: input couldn't be number", 0.75)
            setValue(e.target.value.toString())
          }}
        />
      )
    }
  }
  const isJson = isJsonString(value)

  const renderOptionsInput = () => {
    if (TREE_DIMENSIONS?.includes(segment?.code))
      return (
        <TreeSelect
          values={value ? (isJson ? JSON.parse(value) : value?.split(';')) : []}
          onChange={(value) => {
            setValue(JSON?.stringify(value))
          }}
          handleSearch={(keyword) => setKeyword(keyword)}
          options={options}
        />
      )
    if (segment?.values?.length) {
      if (operator === 'IN_THE_FOLLOWING' || operator === 'NOT_IN_THE_FOLLOWING') {
        return (
          <SearchDropdown
            placeholder="Value"
            values={value ? (isJson ? JSON.parse(value) : value?.split(';')) : []}
            options={options}
            isMulti
            onChange={(value) => {
              setValue(JSON?.stringify(value))
            }}
            handleSearch={(keyword) => setKeyword(keyword)}
          />
        )
      } else if (operator === 'IS_BETWEEN' || operator === 'IS_NOT_BETWEEN') {
        return (
          <SearchDropdown
            placeholder="Value"
            values={value ? value?.split(',').slice(-2) : []}
            options={options}
            isMulti
            onChange={(value) => {
              setValue(value.toString().split(',').slice(-2).join(',').toString())
            }}
            handleSearch={(keyword) => setKeyword(keyword)}
          />
        )
      } else {
        return (
          <SearchDropdown
            placeholder="Value"
            values={value ? [value] : []}
            options={options}
            onChange={(value) => setValue(value?.[0]?.toString() || '')}
            handleSearch={(keyword) => setKeyword(keyword)}
          />
        )
      }
    } else if (operator === 'IS_BETWEEN' || operator === 'IS_NOT_BETWEEN') {
      return renderInput()
    } else {
      return renderInput()
    }
  }

  return (
    <div className="w-full h-full ml-4 p-4">
      <div
        className="CustomerSegmentScreenComponent flex items-center text-sm cursor-pointer"
        onClick={onCancel}
        style={{ width: 'fit-content' }}
      >
        <LeftOutlined className="cursor-pointer" />
        <span className="ml-2">{segment?.name}</span>
      </div>

      <div className="flex mt-6 items-center">
        <span className="mr-2">{segment?.name}</span>
        <div className="mr-2">
          <CommonInputs
            placeholder="Select operator"
            value={operator?.toString() || undefined}
            options={segment?.operator.map((op) => ({ label: op.name, value: op.code }))}
            type="SINGLE_SELECT"
            onChange={(v) => {
              if (timeOperator?.name === 'IN_THE_LAST') {
                setTimeValue('')
              }
              setOperator(v?.toString())
              setValue('')
            }}
          />
        </div>
        <div className="mr-2">{renderOptionsInput()}</div>

        {segment?.time_operator?.length != 0 && (
          <div className="flex">
            <div className="mr-2">
              <CommonInputs
                placeholder="When"
                value={
                  isEditing
                    ? typeof timeOperator == 'object'
                      ? timeOperator?.name
                      : timeOperator
                    : timeOperator?.name ||
                      initialValues?.time_operator?.name?.toString() ||
                      undefined
                }
                options={segment?.time_operator?.map((op) => ({ label: op.name, value: op.code }))}
                type="SINGLE_SELECT"
                onChange={(v) =>
                  setTimeOperator({
                    name: v?.toString(),
                    values: getValues(segment?.time_operator, v?.toString()),
                  })
                }
              />
            </div>
            {timeOperator ? (
              <div className="mr-2" style={{ width: 200 }}>
                <TimeInput
                  timeOperator={timeOperator}
                  value={timeValue}
                  onChange={setTimeValue}
                  isEditing={isEditing}
                />
              </div>
            ) : null}
          </div>
        )}
        {segment?.extra_fields?.filter((e) => e?.code === 'count')?.length ? (
          <div>
            <span className="mr-2">count</span>
            <Input
              style={{ height: 40, borderRadius: 4, width: 100, marginRight: 8 }}
              placeholder="Value"
              value={countValue}
              onChange={(ev) => {
                if (
                  segment?.extra_fields?.filter((e) => e?.code === 'count')?.[0]?.field === 'number'
                ) {
                  ev.target.value
                    .toString()
                    .split(',')
                    .filter((e) => e != '')
                    .some((e) => e.normalize('NFC').match(checkNumRegex)) &&
                    message.warning("Invalid input: input couldn't be text", 0.75)
                  setCountValue(ev.target.value.toString())
                } else {
                  !ev.target.value
                    .toString()
                    .split(',')
                    .filter((e) => e != '')
                    .every((e) => e.normalize('NFC').match(checkNumRegex)) &&
                    message.warning("Invalid input: input couldn't be number", 0.75)
                  setValue(ev.target.value.toString())
                }
              }}
            />
          </div>
        ) : null}

        <Button
          // disabled={testIsDisable()}
          onClick={() => {
            onOk({
              type: segment.code,
              operator,
              value,
              time_operator: timeOperator,
              time_value: timeValue,
              count: countValue,
            })
          }}
          type="text"
          className="font-semibold text-brand_primary cursor-pointer p-0"
        >
          {isEditing ? 'Update' : 'Add'}
        </Button>
      </div>
    </div>
  )
}

export default SegmentScreen
