import React, { useContext, useEffect, useState } from 'react'
import { Tree, Checkbox, Input, Select } from 'antd'
import { useStoreActions, useStoreState } from 'easy-peasy'
import { DATA_NEW, DATA_TEST, MENU_DATA, OPTIONS_SELECT_FILTER } from './constant'
import { formatErrors, searchText as searchTextUtil } from 'Utils'

// import { flattenChannelChildren } from '../../helpers'
import { RiSearchLine } from 'react-icons/ri'
import { DataNode } from 'antd/lib/tree'
import { dimensionPopupContext } from 'Containers/DimensionFilters/Dimensions.Popup'
import { StoreProps } from 'Models/dimensions'
export interface StoreNode extends DataNode {
  store_code?: string
  storeKey?: string
}
const DimensionsMktChannel = () => {
  const { setDimensionsDraft, editingDimension, setEditingDimension, dimensionsDraft } =
    useContext(dimensionPopupContext)
  const { supplierGroup } = useStoreState((state) => state.dimensions)

  const [valueSelect, setValueSelect] = useState<string[]>([])
  const [errors, setErrors] = useState('')
  const [data, setData] = useState<StoreNode[] | any>([])
  const [checkedKeys, setCheckedKeys] = useState<string[]>([])
  const [searchText, setSearchText] = useState('')
  const [originalData, setOriginalData] = useState<StoreNode[]>([])
  useEffect(() => {
    if (supplierGroup) {
      const TreeData = supplierGroup[0]?.dimensions[0]?.values
      setData(TreeData)
      setOriginalData(TreeData)
    }
  }, [supplierGroup])

  useEffect(() => {
    const newDataSelected = getKeySelect(data)
    setCheckedKeys(newDataSelected)
  }, [valueSelect])

  useEffect(() => {
    if (dimensionsDraft) {
      let dataSelected: string[] = []
      dimensionsDraft.forEach((dim) => {
        if (dim?.segments[0].type === 'SUPPLIER') {
          dataSelected = dim?.segments[0].value.split(',')
        }
      })
      setCheckedKeys(dataSelected)
    }
  }, [dimensionsDraft])

  const onSetDimensions = (value: string[]) => {
    setDimensionsDraft({
      type: 'addChannel',
      payload: {
        dimension: {
          segments: [
            {
              audience_Type: 'audience',
              type: 'SUPPLIER',
              operator: 'IN_THE_FOLLOWING',
              value: value.toString(),
              time_value: '',
            },
          ],
          condition: dimensionsDraft[1]?.condition || 'AND',
        },
      },
    })
  }

  const FilterData = (condition) => (data) => {
    return data.reduce((acc, obj) => {
      if (obj.children) return [...acc, { ...obj, children: FilterData(condition)(obj.children) }]
      else if (condition(obj)) return [...acc, obj]
      return acc
    }, [])
  }

  const FilterDataSelected = (condition) => (data) => {
    return data.reduce((acc, obj) => {
      if (obj.children)
        return [...acc, { ...obj, children: FilterDataSelected(condition)(obj.children) }]
      else if (condition(obj)) return [...acc, obj]
      return acc
    }, [])
  }

  React.useMemo(() => {
    if (!searchText) return setData(originalData)
    const newData = FilterData(({ title }) =>
      title.toLowerCase().includes(searchText.toLowerCase())
    )(originalData)
    setData(newData)
  }, [searchText])

  let getAllKeys = (tree: any) => {
    let result: any = []
    tree.forEach((x) => {
      let childKeys = []
      if (x.children) {
        childKeys = getAllKeys(x.children)
      }
      result.push(...[x.key, ...childKeys])
    })
    return result
  }

  const getKeySelect = (tree: any) => {
    let result: any = []
    tree.forEach((item) => {
      let childrenKey = []
      if (item.children) {
        childrenKey = getKeySelect(item.children)
      } else {
        if (valueSelect.length == 2) {
          const value1 = valueSelect[0]
          const value2 = valueSelect[1]
          if (
            item.title.toLowerCase().includes(value1) ||
            item.title.toLowerCase().includes(value2) ||
            item.title.toLowerCase().includes('mini')
          ) {
            result.push(...[item.key, ...childrenKey])
          }
        }
        if (valueSelect.length == 1) {
          const value1 = valueSelect[0]
          if (value1.includes('mini')) {
            if (item.title.toLowerCase().includes('mini')) {
              result.push(...[item.key, ...childrenKey])
            }
          } else {
            if (
              item.title.toLowerCase().includes(value1) &&
              !item.title.toLowerCase().includes('mini')
            ) {
              result.push(...[item.key, ...childrenKey])
            }
          }
        }
      }
      result.push(...childrenKey)
    })
    return result
  }

  const filterTreeNode = (node: any) => {
    return node?.title?.toLowerCase().includes(searchText.toLowerCase())
  }

  const handleChange = (value) => {
    // console.log(`selected ${value}`)
  }

  const onChangeSearch = (event) => {
    setSearchText(event.target.value)
  }

  const onCheck = (e) => {
    if (e?.checked === true) {
      setCheckedKeys((prev) => {
        onSetDimensions([...prev, e.node.key])
        return [...prev, e.node.key]
      })
    } else {
      setCheckedKeys((prev) => {
        let filterArray = prev.filter((key) => key.toLowerCase() != e.node.key.toLowerCase())
        onSetDimensions(filterArray)
        if (!filterArray.length) {
          dimensionsDraft.map((dimension, index) => {
            if (dimension?.segments[0].type == 'SUPPLIER') {
              setDimensionsDraft({ type: 'remove', payload: { index } })
            }
          })
        }
        return filterArray
      })
    }
  }

  const handleOnChangeSelect = (e) => {
    setValueSelect(e)
  }

  const allKeys = getAllKeys(data)

  const onCheckAll = () => {
    if (checkedKeys?.length === allKeys?.length) {
      // setDimensionsDraft({ type: 'clear' })
      dimensionsDraft &&
        dimensionsDraft.map((dimension, index) => {
          if (dimension?.segments[0].type == 'SUPPLIER') {
            setDimensionsDraft({ type: 'remove', payload: { index } })
          }
        })
      setCheckedKeys([])
    } else {
      setCheckedKeys(allKeys)
      onSetDimensions(allKeys)
    }
  }

  const renderDropdown = (
    <div>
      <Checkbox.Group value={valueSelect} onChange={handleOnChangeSelect}>
        <div className="p-2">
          {OPTIONS_SELECT_FILTER.map((item) => (
            <div
              key={item.value}
              className={`px-3 py-1 ${valueSelect.includes(item.value) ? 'bg-hover_overlay' : ''} `}
            >
              <Checkbox value={item.value}>{item.label}</Checkbox>
            </div>
          ))}
        </div>
      </Checkbox.Group>
    </div>
  )
  return (
    <div className="p-4">
      <div className="flex items-center justify-between">
        <div className="flex-1 mr-4">
          <Input
            onChange={(e) => onChangeSearch(e)}
            value={searchText}
            placeholder="Search"
            prefix={<RiSearchLine />}
          />
        </div>
        {/* <div>
          <Select
            defaultValue="Quick Select"
            style={{
              width: 200,
            }}
            onChange={handleChange}
            dropdownRender={() => renderDropdown}
          />
        </div> */}
      </div>
      <div className="mt-4 ml-6">
        {!searchText && (
          <Checkbox
            onChange={onCheckAll}
            checked={checkedKeys?.length === allKeys?.length ? true : false}
          >
            <div style={{ marginLeft: 4, fontSize: 16 }}>All</div>
          </Checkbox>
        )}
      </div>
      <div>
        <Tree
          checkedKeys={checkedKeys || undefined}
          checkable
          onCheck={(keys, nodes) => {
            onCheck(nodes)
          }}
          treeData={data}
          filterTreeNode={filterTreeNode}
        />
      </div>
    </div>
  )
}

export default DimensionsMktChannel
